export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  const mainStore = useMainStore();

  nuxtApp.hook('page:loading:start', () => {
    mainStore.startLoading();
  });

  nuxtApp.hook('page:loading:end', () => {
    mainStore.finishLoading();

    if (import.meta.client) {
      window.dispatchEvent(new CustomEvent('page:loading:end', { detail: { firstLoad: mainStore.firstLoad } }));
      mainStore.firstLoad = false;
    }
  });
});
