import { logger } from '@/utils/logger';
import type { RouteMiddleware, Plugin } from '#app';

// Safely import (and re-export) node modules for SSR without causing errors during CSR
export const performance = import.meta.server ? (await import('node:perf_hooks')).performance : window.performance;
const fileURLToPath = import.meta.server ? (await import('node:url')).fileURLToPath : undefined;
const relative = import.meta.server ? (await import('node:path')).relative : undefined;

export const createClickableLink = (value: string) => {
  if (!fileURLToPath || !relative || !value.includes('file://')) {
    return value;
  }

  const fullPath = fileURLToPath(value);
  const shortPath = relative(process.cwd(), fullPath);

  // ANSI escape sequence for clickable links, should be supported by most terminals
  return `\x1B]8;;${fullPath}\x1B\\${shortPath}\x1B]8;;\x1B\\`;
};

export const useWithTiming = async <T extends Function>(name: string | (() => string) | undefined, handler: T) => {
  if (!name || !logger.isEnabled('debug')) {
    // Just run the handler
    return handler();
  }

  // Run the handler and collect timings
  const start = performance.now();
  const returnValue = await handler();
  const end = performance.now();

  const nameValue = typeof name === 'function' ? name() : name;

  // Log timings
  logger.debug(`[${(end - start).toFixed(3)}ms]`, import.meta.client ? nameValue : createClickableLink(nameValue));

  return returnValue;
};

/* eslint-disable prettier/prettier */
export const defineNuxtPluginWithTiming =  (name: string, handler: Plugin) =>
   defineNuxtPlugin(async (nuxtApp) =>
     useWithTiming(name, async () => await handler(nuxtApp)));

export const defineNuxtRouteMiddlewareWithTiming =  (name: string, handler: RouteMiddleware) =>
   defineNuxtRouteMiddleware( async (to, from) =>
     useWithTiming(name, async () => await handler(to, from)));
