function mapAcceptArray(type: string) {
  return type.startsWith('.') ? type : `.${type}`;
}

export function useComputedInputAcceptAttribute(accept: string | string[] = ''): ComputedRef<string> {
  const computedAccept = computed(() => {
    if (Array.isArray(accept)) {
      return accept.map(mapAcceptArray).join(', ');
    }

    return accept;
  });

  return computedAccept;
}
