/**
 * The main language file to load i18n configuration from the backend.
 *
 * Note that auto-imports do not work here, this file is treated
 */

import axios, { type AxiosRequestConfig } from 'axios';
import logger from '@/utils/logger';
import { getBaseStoreAndLanguage } from '@/utils/baseStores';

const USE_ASYNC_DATA = true;

const MESSAGES = {
  SENDING: `Loading translations...`,
  ERROR: `Failed to load translation:`,
  ERROR_MISSING_VALUE: 'Missing value for variable:',
  ERROR_MISSING_CONFIG: `Missing 'configure/i18n' request 'config' parameter`,
  ERROR_EMPTY_RESPONSE: `Empty 'configure/i18n' request response`,
  ERROR_CAUSE_PROCESSING: 'Error: Error during error cause processing, unable to proceed',
};

const getHost = () => {
  let host: string | undefined;

  if (import.meta.client) {
    host = window.location.host;
  } else {
    host = useRequestHeader('host');
    host = (Array.isArray(host) ? host[0] : host).replace(/(http|https):\/\//, '').split(':')[0];
  }

  if (!host) throw new Error(`${MESSAGES.ERROR_MISSING_VALUE} host`);

  return host;
};

const getUrl = () => {
  const url = unref(useRoute()).fullPath;

  if (!url) throw new Error(`${MESSAGES.ERROR_MISSING_VALUE} url`);

  return url;
};

const fetchTranslations = async (config: AxiosRequestConfig) => {
  if (!config || !Object.keys(config).length) {
    throw new Error(MESSAGES.ERROR_MISSING_CONFIG);
  }

  logger.debug(MESSAGES.SENDING);
  logger.debug(config?.method?.toUpperCase(), config.url);

  const { data } = await axios.request(config);

  if (!Object.keys(data).length) {
    throw new Error(MESSAGES.ERROR_EMPTY_RESPONSE);
  }

  return data;
};

export default defineI18nLocale(async function loadTranslations(locale) {
  const { $config } = useNuxtApp();
  const { hybrisBaseUrl, hybrisApiPath } = $config.public;
  const i18nCookie = unref(useCookie('i18n_redirected')) ?? '';

  try {
    const { baseStore, language, isMultilang } = getBaseStoreAndLanguage(getHost(), getUrl(), i18nCookie, $config);

    // Create request parameter
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${hybrisBaseUrl}${hybrisApiPath}/${baseStore}/configuration/i18n`,
      params: {
        lang: isMultilang ? language : null,
      },
    };

    // There are some edge cases to be aware of:
    //  - DO NOT wrap this in useAsyncData(), "duplicated" SSR/CSR request is better
    //    than big entry file, see https://github.com/nuxt-modules/i18n/issues/2815
    //  - DO NOT remove the `await`, despite being common JS behaviour it will fail
    //    silently if omitted thanks to the special nature of this file
    if (USE_ASYNC_DATA) {
      const { data } = await useAsyncData(() => fetchTranslations(config), { deep: false });
      return unref(data);
    } else {
      return await fetchTranslations(config);
    }
  } catch (error: unknown) {
    showError({
      statusCode: 500,
      message: `${MESSAGES.ERROR} ${locale}`,
      fatal: true,
    });
  }
});
