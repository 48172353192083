import { pascalCase } from 'change-case';
import { type AsyncComponentLoader, type Component, type HydrationStrategy, hydrateOnVisible } from 'vue';
import FnCmsDefault from '@/components/cms/components/CmsDefault.vue';
import FnLoadingOverlay from '@/components/atoms/loading-overlay/LoadingOverlay.vue';

type ComponentLoaderProps = {
  type: string;
};

type ComponentLoaderOptionsBase = {
  hydrate?: HydrationStrategy;
  fallbackComponent?: false | string | Component;
};

type ComponentLoaderOptions<T extends ComponentLoaderProps> = ComponentLoaderOptionsBase & {
  props: T;
  componentResolver: (name: string) => Promise<Component>;
  componentNameOverrides?: Record<string, string>;
};

type ComponentLoaderGlobOptions = ComponentLoaderOptionsBase;

const regexImportAlias = /^[@~]+/;

const normalizeName = (name: string, overrides?: Record<string, string>) =>
  `${pascalCase(`${overrides?.[name] || name}`)}`;

const normalizePath = (path: string) => path.replace(regexImportAlias, '');

const getFallbackComponent = (fallbackComponent: ComponentLoaderOptionsBase['fallbackComponent'], name: string) =>
  h(fallbackComponent || '', import.meta.dev && fallbackComponent ? { 'data-missing-component': name } : {});

/**
 * Add data-attribute showing which component was lazy-hydrated without affecting prod builds
 */
const render = (component: string | Component, hydrate?: HydrationStrategy) => {
  if (import.meta.dev) {
    return h(component, {
      'data-lazy-hydrate': !!hydrate,
    });
  } else {
    return component;
  }
};

/**
 * Load components using a dynamic name.
 *
 * Use this and not `useComponentLoaderGlob` when using a fixed path but dynamic name, as it uses less resources.
 *
 * Dynamic imports are transformed to import.meta.glob during build time, so it should have similar performance
 * implications than the more flexible `useComponentLoaderGlob`.
 *
 * @param hydrate                 Component hydration strategy for delayed/lazy hydration.
 * @param fallbackComponent       Component to use when a component could not be loaded.
 * @param props                   Component props which should have `type` to use for mapping.
 * @param componentNameOverrides  Component names which don't match `type` can be mapped here.
 * @param componentResolver       Callback function which imports the component, needed due to these restrictions:
 *                                  - Must start with hardcoded `./`
 *                                  - Must use hardcoded path
 *                                  - Must use hardcoded extension
 *                                  - Can use dynamic name
 *                                  - Can not have part of path or extension in dynamic name
 *
 * @example Basic usage
 *  <template>
 *    <component v-bind="props" :is="DynamicComponent" />
 *  </template>
 *  <script setup>
 *  const DynamicComponent = useComponentLoader({
 *    props,
 *    componentResolver: (name) => import(`./../components/Cms${name}.vue`),
 *    componentNameOverrides: {
 *      image_text_panel: 'ErrorIntro',
 *      related_posts_table: 'LinkTeaserList',
 *    },
 *  });
 *  </script>
 *
 *  <component v-bind="props" :is="DynamicComponent" />
 *
 * @see https://vitejs.dev/guide/features#dynamic-import based on this
 */
export const useComponentLoader = <T extends ComponentLoaderProps>({
  hydrate = hydrateOnVisible(),
  fallbackComponent = FnCmsDefault,
  props,
  componentResolver,
  componentNameOverrides,
}: ComponentLoaderOptions<T>) => {
  const componentName = normalizeName(props.type, componentNameOverrides);
  const errorComponent = getFallbackComponent(fallbackComponent, componentName);

  return render(
    defineAsyncComponent({
      loadingComponent: FnLoadingOverlay,
      errorComponent,

      // Delay before loading component is displayed
      delay: 200,

      // Delay before error component is displayed
      timeout: 5000,

      // Hydration strategy, either default or lazy hydrate possible
      hydrate,

      loader: async () =>
        componentResolver(componentName).catch((error: Error) => {
          import.meta.dev && useNuxtApp().$log.error('Failed resolving dynamic component', componentName, error);
          return errorComponent;
        }),

      // Retry loading the component up to 3 times, in case of real connectivity problems
      onError: (error, retry, fail, attempts) => {
        if (error.message.match(/fetch/) && attempts <= 3) {
          return retry();
        }

        import.meta.dev && useNuxtApp().$log.error('Failed loading dynamic component', componentName, error);
        return fail();
      },
    }),
    hydrate,
  );
};

/**
 * Loads components using dynamic path and name.
 *
 * @param hydrate
 * @param modules
 * @param fallbackComponent
 *
 * @example Basic usage
 *  <template>
 *    <component v-bind="props" :is="DynamicComponent" />
 *  </template>
 *  <script setup>
 *  const globImport = useComponentLoaderGlob({ modules: import.meta.glob('@/components/cms/components/*.vue') });
 *  const DynamicComponent = computed(() => globImport(`@/components/cms/components/Cms${pascalCase(props.type)}.vue`));
 *  </script>
 *
 * @see https://vitejs.dev/guide/features#glob-import-caveats
 */
export const useComponentLoaderGlob = (
  modules: Record<string, () => Promise<AsyncComponentLoader<Component>>>,
  { hydrate, fallbackComponent }: ComponentLoaderGlobOptions = {
    hydrate: hydrateOnVisible(),
    fallbackComponent: FnCmsDefault,
  },
) => {
  // Return closure which can be used to import the dynamic components
  return (path: string) => {
    const componentModulePath = normalizePath(path);

    if (!modules[componentModulePath]) {
      if (fallbackComponent) {
        import.meta.dev && useNuxtApp().$log.error('Failed resolving dynamic component', componentModulePath, modules);
        return render(fallbackComponent, hydrate);
      } else {
        return null;
      }
    }

    return render(
      defineAsyncComponent({
        hydrate,
        loader: modules[componentModulePath],
      }),
      hydrate,
    );
  };
};
