import type { PageDataWithCacheKey, PageDataWithEmail, PageDataWithToken } from '@/types/pageData';

export async function useCacheKeyedPageData(cacheKey: string) {
  const { $api, $log } = useNuxtApp();
  const mainStore = useMainStore();
  const route = useRoute();

  const { data, error } = await useAsyncData(cacheKey, async (): Promise<PageDataWithCacheKey | void> => {
    const data: PageDataWithCacheKey = {
      page: null,
      cacheKey: null,
    };

    try {
      const pageUrl = removeLanguagePrefix(route.path);

      data.cacheKey = generateCacheKeyPage(mainStore.language, mainStore.baseStore, route);
      data.page = await $api.getPage(encodeURI(pageUrl));
    } catch (e) {
      $log.error(e);
      return useCmsErrorPage();
    }

    return data;
  });

  return { data, error };
}

export async function usePageDataWithEmail(cacheKey: string) {
  const { $api, $i18n, $log } = useNuxtApp();
  const route = useRoute();
  const router = useRouter();

  const { data, error } = await useAsyncData(cacheKey, async (): Promise<PageDataWithEmail | void> => {
    const data: PageDataWithEmail = {
      page: null,
      email: router.currentRoute.value.query?.email as string,
    };

    if (!data.email) {
      await router.replace($i18n.t('url.password.request'));
    }

    try {
      const pageUrl = removeLanguagePrefix(route.path);

      data.page = await $api.getPage(encodeURI(pageUrl));
    } catch (e) {
      $log.error(e);
      return useCmsErrorPage();
    }

    return data;
  });

  return { data, error };
}

export async function usePageDataWithTokenAndRedirect(cacheKey: string) {
  const { $api, $log } = useNuxtApp();
  const route = useRoute();

  const { data, error } = await useAsyncData(cacheKey, async (): Promise<PageDataWithToken | void> => {
    const data: PageDataWithToken = {
      page: null,
      token: (route.query.token as string) || null,
      redirect: (route.query.redirect as string) || null,
    };

    try {
      const pageUrl = removeLanguagePrefix(route.path);

      data.page = await $api.getPage(encodeURI(pageUrl));
    } catch (e) {
      $log.error(e);
      return useCmsErrorPage();
    }

    return data;
  });

  return { data, error };
}
