import * as Sentry from '@sentry/vue';

/**
 * Client-side Sentry integration for Nuxt, initializes and provides `$sentry` to Nuxt client-side context.
 * @see https://www.lichter.io/articles/nuxt3-sentry-recipe used as a reference
 * @see https://github.com/nuxt-community/sentry-module/pull/547 ~100-200kb smaller client bundle with dynamic imports
 */
export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  const sentryConfig = nuxtApp.$config.public.sentry;

  if (sentryConfig.enabled) {
    Sentry.init({
      ...resolveSentryConfigShared(),

      integrations: [
        Sentry.browserTracingIntegration({
          routeLabel: 'path',
          router: nuxtApp.$router,
        }),

        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
          sessionSampleRate: sentryConfig.replays.sessionSampleRate ?? 0.0,
          errorSampleRate: sentryConfig.replays.onErrorSampleRate ?? 0.05,
        }),

        Sentry.breadcrumbsIntegration(),
        Sentry.dedupeIntegration(),
        Sentry.browserApiErrorsIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.linkedErrorsIntegration(),
        Sentry.vueIntegration({
          hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
          logErrors: true,
          attachProps: true,
          trackComponents: true,
          timeout: 500,
          tracingOptions: {
            timeout: 1000,
          },
        }),
      ],

      app: nuxtApp.vueApp,
      trackComponents: true,
    });
  }

  return {
    provide: {
      sentry: Sentry,
    },
  };
});
