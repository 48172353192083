export const resourceNames = {
  CART: 'CART',
  ORDER: 'ORDER',
  USER: 'USER',
  WISHLIST: 'WISHLIST',
};

/**
 *
 * @param {string | Array<string>} resourceList
 */
export const getFormattedResourceList = (resourceList) => {
  if (!resourceList) {
    return [];
  }
  if (typeof resourceList === 'string') {
    return resourceList.split(',').filter((resource) => resourceNames[resource]);
  }
  if (Array.isArray(resourceList)) {
    return resourceList.filter((resource) => resourceNames[resource]);
  }

  return [];
};
