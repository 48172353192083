export const DELIVERY_INVOICE = 'invoice';
export const DELIVERY_NEW = 'new_delivery_address';

export const DELIVERY_DK_STATION = 'dk-pickupstation-shipping';
export const DELIVERY_FR_STATION = 'fr-pickupstation-shipping';

export const DELIVERY_DK_STORE = 'dk-pickup-in-store-shipping';
export const DELIVERY_FR_STORE = 'fr-click-and-collect-shipping';
export const DELIVERY_AT_STORE = 'at-click-and-collect-shipping';
export const DELIVERY_CH_STORE = 'ch-click-and-collect-shipping';
export const DELIVERY_PL_STORE = 'pl-click-and-collect-shipping';
export const DELIVERY_DE_STORE = 'de-click-and-collect-shipping';
export const DELIVERY_BE_STORE = 'be-click-and-collect-shipping';
export const DELIVERY_LU_STORE = 'lu-click-and-collect-shipping';

export const DELIVERY_ADDRESS_TYPE_STORE = 'STORE';
export const DELIVERY_ADDRESS_TYPE_PICK_UP_STATION = 'PICK_UP_STATION';
export const DELIVERY_ADDRESS_TYPE_DELIVERY = 'DELIVERY';
