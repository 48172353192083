import ErrorHandler from '@/services/ErrorHandler';

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  /**
   * Startup errors
   * @see https://nuxt.com/docs/getting-started/error-handling#startup-errors
   */
  nuxtApp.hook('app:error', (err) => {
    /* @todo: Improvement: Send error to Sentry (dev, prod) */
    /* @todo: Improvement: Show error modal/page (dev, prod?) */
    nuxtApp.$log.error('[storefront:error:app]', err);
  });

  /**
   * Vue lifecycle errors - including unhandled and handled errors
   * @see https://nuxt.com/docs/getting-started/error-handling#vue-errors
   */
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    /* @todo: Improvement: Send error to Sentry (dev, prod) */
    /* @todo: Improvement: Show error modal/page (dev, prod?) */
    nuxtApp.$log.error('[storefront:error:vue]', { error, instance, info });
  };

  /**
   * Vue lifecycle errors - includes handled errors only
   * @see https://nuxt.com/docs/getting-started/error-handling#vue-errors
   */
  // nuxtApp.hook('vue:error', (error, instance, info) => {
  //   // logger.error('[storefront:error:vue:unhandled]', { error, instance, info });
  // });

  nuxtApp.$router.onError((error) => {
    nuxtApp.$log.error('[storefront:error:router]', error);
  });

  return {
    provide: {
      errorHandler: new ErrorHandler(),
    },
  };
});
