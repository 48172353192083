export const getTrackingId = (trackingId, name) => {
  if (!trackingId) {
    return undefined;
  }

  const lastChar = trackingId.slice(-1);
  const separator = lastChar === '|' ? '' : '-';

  if (!name) {
    return trackingId;
  }

  return `${trackingId}${separator}${name}`;
};

export default getTrackingId;
