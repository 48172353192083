// eslint-disable-next-line import/prefer-default-export
export const generateIdFromUrl = url => {
  if (!url) {
    return 'no-url';
  }

  const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  // Check if 'url' is valid URL
  if (url.match(urlRegexp)) {
    const urlObj = new URL(url);

    // Split host string and remove the www part if existing
    let urlArr = urlObj.host.split('.');
    urlArr = urlArr.filter(el => el !== 'www');

    // Add pathname to final ID in order to make it unique
    if (urlObj.pathname) {
      urlArr = [...urlArr, ...urlObj.pathname.split('/').filter(i => i)];
    }

    return urlArr.join('.');
  }

  return url
    .split('/')
    .filter(i => i)
    .join('.');
};
