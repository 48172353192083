import { TEMPLATE_FLEXIBLE_LAYOUT } from '@/utils/cmsTemplateNames';

export const useFlexibleLayout = (page: any) => {
  const mainStore = useMainStore();

  if (mainStore.pageTemplate !== TEMPLATE_FLEXIBLE_LAYOUT) {
    return;
  }

  const flexHeader = page?.content.flexible_header || null;
  const flexFooter = page?.content.flexible_footer || null;
  const flexLayoutType = page?.content.content?.[0]?.acf_fc_layout;

  mainStore.flexibleHeader = flexHeader;
  mainStore.flexibleFooter = flexFooter;
  mainStore.flexibleLayoutType = flexLayoutType;

  // Disable Nav Bar for New pet page
  if (flexLayoutType === 'new_pet') {
    mainStore.salesDisabled = true;
  }
};
