export const localeDomains = {
  de: process.env.NUXT_PUBLIC_DOMAIN_DE,
  at: process.env.NUXT_PUBLIC_DOMAIN_AT,
  lu: process.env.NUXT_PUBLIC_DOMAIN_LU,
  pl: process.env.NUXT_PUBLIC_DOMAIN_PL,
  fr: process.env.NUXT_PUBLIC_DOMAIN_FR,
  fr_BE: process.env.NUXT_PUBLIC_DOMAIN_BE,
  nl_BE: process.env.NUXT_PUBLIC_DOMAIN_BE,
  de_CH: process.env.NUXT_PUBLIC_DOMAIN_CH,
  fr_CH: process.env.NUXT_PUBLIC_DOMAIN_CH,
  ie: process.env.NUXT_PUBLIC_DOMAIN_IE,
  dk: process.env.NUXT_PUBLIC_DOMAIN_DK,
};
