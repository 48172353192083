export const getAfterpayConfig = (config) => ({
  fingerprinting: {
    script: config.public.afterpayFingerprintingScript,
    clientId: config.public.afterpayFingerprintingClientId,
  },
  directDebit: {
    script: config.public.afterpayDirectDebitScript,
    baseAddress: config.public.afterpayDirectDebitBaseAddress,
  },
});

export const getPaypalConfig = (config) => ({
  fraudnet: {
    script: config.public.paypalFraudnetScript,
    image: `${config.public.paypalFraudnetImage}&s=${config.public.paypalFraudnetIdentifier}`,
    identifier: config.public.paypalFraudnetIdentifier,
  },
});

export const getAdyenConfig = (config) => ({
  clientKey: config.public.adyenClientkey,
  environment: config.public.adyenEnvironment,
});

export const paymentRedirect = ({ method, url = '', params = [] }) => {
  if (method === 'POST') {
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', url);
    form.setAttribute('target', '_self');

    for (const entry of params) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', entry.key);
      hiddenField.setAttribute('value', entry.value);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
    return;
  }

  window.location.href = url;
};

export const paymentErrorRedirect = (error) => {
  setTimeout(() => {
    const singleError = error?.response?.data?.errors?.length === 1 ? error.response.data.errors[0] : null;

    switch (singleError?.subject) {
      case 'refused':
        window.location.href = '/checkout/payment/?refused=true';
        break;

      case 'cancelled':
        window.location.href = '/checkout/payment/?cancelled=true';
        break;

      default:
        window.location.href = '/checkout/payment/?error=true';
        break;
    }
  }, 2000);
};

export default {
  getAfterpayConfig,
  getPaypalConfig,
  getAdyenConfig,
  paymentRedirect,
  paymentErrorRedirect,
};
