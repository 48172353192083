export const englishWeekdays = [
  // Keep it in the same order like in the translation files for "weekDays"!
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const ONE_YEAR = 60 * 60 * 24 * 365;

const sanitizeDatestringForIE11 = (dateString) => {
  if (!dateString) {
    return null;
  }

  const offset = dateString.substr(-5);

  // IE11 does not like date strings with timezone offset format +0000 -> Convert to +00:00
  if (offset.match(/\+\d{4}/)) {
    return `${dateString.substr(0, 19)}${offset.substr(0, 3)}:${offset.substr(3)}`;
  }

  return dateString;
};

const getDateLocale = () => (import.meta.client && navigator.language ? navigator.language : 'de-DE');

const pad = (value) => value.toString().padStart(2, '0');

export const createDateObject = (dateString) => {
  if (typeof dateString !== 'string') {
    return dateString;
  }

  const sanitizedDateString = sanitizeDatestringForIE11(dateString);

  let date = new Date(sanitizedDateString);

  // Safari < 16 will result in 'Invalid Date' for the format YYYY-MM-DD HH:MM:SS
  // In this case, replace the space with a 'T'
  // Do not use Number.isNaN here! It will not return true for 'Invalid Date'

  if (isNaN(date)) {
    date = new Date(sanitizedDateString.replace(' ', 'T'));
  }

  return date;
};

// region Format fixed
export const formatDateStringToDDMMYYYY = (dateString) => {
  const date = createDateObject(dateString);
  return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()}`;
};

export const formatDateStringToDDMMYY = (dateString) => {
  const date = createDateObject(dateString);
  return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${pad(date.getFullYear())}`;
};

export const formatDateStringToYYYYMMDD = (dateString) => {
  const date = createDateObject(dateString);
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

export const formatDateStringToHHMM = (dateString) => {
  const date = createDateObject(dateString);
  return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
};
// endregion

// region Format locale
export const formatLocaleDate = (date, opts = {}) => createDateObject(date).toLocaleDateString(getDateLocale(), opts);

export const formatLocaleTime = (date, opts = {}) => createDateObject(date).toLocaleTimeString(getDateLocale(), opts);

export const formatDateStringLeadingZero = (date) =>
  formatLocaleDate(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

export const formatDateStringFullDay = (date) =>
  formatLocaleDate(date, {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const formatDateStringShortTime = (date) =>
  formatLocaleTime(date, {
    hour: '2-digit',
    minute: '2-digit',
  });
// endregion

// Other utils
export const splitDate = (value) => {
  if (typeof value !== 'string') return null;
  const matches = value.match(/^(.{0,4})-(.{1,2})-(.{1,2})/);

  if (!matches) return null;

  const [, year, month, day] = matches;

  return {
    year: parseInt(year || '0', 10),
    month: parseInt(month || '0', 10),
    day: parseInt(day || '0', 10),
  };
};

export const ageCheck = (value, min = 18) => {
  const minAge = new Date(new Date().setFullYear(new Date().getFullYear() - min));
  const birthday = new Date(value);
  // Check if underage-date was given
  if (minAge - birthday < 0) {
    return false;
  }

  return true;
};

export const monthCheck = (value) => {
  const today = new Date();
  const birthDate = new Date(value);
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  const ageInMonths = age * 12 + m;

  return ageInMonths;
};

export const addDaysToNow = (days) => {
  const daysInMs = days * 24 * 60 * 60 * 1000;
  return new Date(Date.now() + daysInMs);
};

export const orderDateIsRecent = (createdDay) => {
  let isRecent = false;
  const orderDate = new Date(createdDay);
  const now = new Date();

  // 60d, 24h, 60min, 60sec, 1000ms
  const recentDaysInMs = 60 * 24 * 60 * 60 * 1000;

  const timeDiffInMs = now.getTime() - orderDate.getTime();

  if (timeDiffInMs < recentDaysInMs) {
    isRecent = true;
  }

  return isRecent;
};

export const getLastMonths = (x) => {
  const today = new Date();
  const xMonthsAgo = new Date();
  xMonthsAgo.setMonth(today.getMonth() - x);

  return {
    newerThanTS: `${xMonthsAgo.toISOString().slice(0, 10)}T00:00:00+0000`,
    olderThanTS: `${today.toISOString().slice(0, 10)}T23:59:59+0000`,
  };
};

export const datesAreSame = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const dateIsToday = (dateString) => datesAreSame(new Date(), new Date(dateString));
