export default class AbstractApi {
  constructor(client, logger, config, isAppview = false) {
    if (import.meta.dev && !client) {
      throw new Error('Missing client');
    }
    if (import.meta.dev && !logger) {
      throw new Error('Missing logger');
    }

    this.client = client;
    this.logger = logger;
    this.config = config;
    this.baseUrl = '';
    this.basePath = '';
    this.headers = {};
    this.isAppview = isAppview;
  }

  /**
   * @param config {AxiosRequestConfig | Record<string, any>}
   * @param shouldLogData Set false to omit request payload in logs.
   * @returns {Promise<AxiosResponse>}
   */
  async request(config, shouldLogData = true) {
    config.url = this.getURL(config.url, config.version);
    config.headers = this.getHeaders(config.headers, config.abTests);

    let logStringValue = this.logger.isEnabled('debug') ? `${config.method.toUpperCase()} ${config.url}` : '';

    const getLogString = () => {
      return logStringValue;
    };

    return this.config.useWithTiming(getLogString, async () => {
      const response = await this.client.request(config).catch((error) => {
        // Access token gets invalidated after a long time (app in background)
        // In this case, do a browser refresh to get a refreshed token for WebViews (in app)
        if (import.meta.client && unref(this.isAppview) && !config.isTokenApi && error.response?.status === 401) {
          this.logger.debug('Reloading Page due to unauthorized ajax request in appview');
          window.location.reload();
          return;
        }

        throw error;
      });

      const logData = await this.getLogData(shouldLogData, config, response);

      // Append log data for usage by callback
      if (Object.keys(logData).length) {
        logStringValue += ` ${this.logger.inspect(logData)}`;
      }

      return response;
    });
  }

  getURL(endpoint, version) {
    const basePath = version ? `/${version}` : this.basePath;
    return `${this.baseUrl}${basePath}${endpoint}`;
  }

  getHeaders(headers, abTests) {
    if (!headers) {
      return this.headers;
    }

    if (abTests) {
      return {
        ...this.headers,
        ...headers,
        'FN-UX': JSON.stringify(abTests || {}),
      };
    }

    return {
      ...this.headers,
      ...headers,
    };
  }

  setAccessToken(accessToken) {
    if (!accessToken) {
      delete this.headers.Authorization;

      return this;
    }

    this.headers.Authorization = `Bearer ${accessToken}`;

    return this;
  }

  async getLogData(shouldLogData, config, response) {
    if (!this.logger.isEnabled('debug')) {
      return {};
    }

    let configCopy;

    try {
      configCopy = JSON.parse(JSON.stringify(config));

      if (!shouldLogData) {
        delete configCopy.data;
      } else {
        // Always delete sensitive user data
        delete configCopy.data?.username;
        delete configCopy.data?.password;
      }

      // Delete redundant stuff
      delete configCopy.version;
      delete configCopy.lang;
      delete configCopy.cache;

      // Delete when headers might be empty, keeps the logs cleaner
      if (!Object.keys(configCopy.headers).length) {
        delete configCopy.headers;
      }

      // Add cache info if available
      if (this.client.storage?.data?.[response?.config?.id]) {
        const remaining =
          this.client.storage?.data?.[response?.config?.id]?.ttl -
          (Date.now() - this.client.storage?.data[response?.config?.id]?.createdAt);

        configCopy.cache = {
          type: this.client.storage?.name,
          state: this.client.storage?.data?.[response?.config?.id]?.state,
          remaining: `${(remaining / 1000).toFixed(0)}s`,
        };
      }
    } catch (error) {
      this.logger.error('Error occurred while parsing request config:', error);
    }

    delete configCopy.method;
    delete configCopy.url;

    if (!Object.keys(configCopy).length) {
      return {};
    }

    return configCopy;
  }
}
