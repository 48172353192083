import {
  DELIVERY_ADDRESS_TYPE_STORE,
  DELIVERY_ADDRESS_TYPE_PICK_UP_STATION,
  DELIVERY_FR_STORE,
  DELIVERY_DK_STORE,
  DELIVERY_AT_STORE,
  DELIVERY_DK_STATION,
  DELIVERY_FR_STATION,
} from '../utils/deliveryConstants';

export function useDeliveryHelpers() {
  const { baseStore } = useMainStore();

  const config = useRuntimeConfig();

  const deliveryModesMapping = {
    [DELIVERY_ADDRESS_TYPE_STORE]: {
      [config.public.baseStoreAt]: DELIVERY_AT_STORE,
      [config.public.baseStoreBe]: DELIVERY_BE_STORE,
      [config.public.baseStoreCh]: DELIVERY_CH_STORE,
      [config.public.baseStoreDe]: DELIVERY_DE_STORE,
      [config.public.baseStoreDk]: DELIVERY_DK_STORE,
      [config.public.baseStoreFr]: DELIVERY_FR_STORE,
      [config.public.baseStoreLu]: DELIVERY_LU_STORE,
      [config.public.baseStorePl]: DELIVERY_PL_STORE,
    },
    [DELIVERY_ADDRESS_TYPE_PICK_UP_STATION]: {
      [config.public.baseStoreDk]: DELIVERY_DK_STATION,
      [config.public.baseStoreFr]: DELIVERY_FR_STATION,
    },
  };

  const deliveryTypeStationPerCountry = computed(() => {
    return Object.values(deliveryModesMapping[DELIVERY_ADDRESS_TYPE_PICK_UP_STATION]);
  });

  const deliveryTypeStorePerCountry = computed(() => {
    return Object.values(deliveryModesMapping[DELIVERY_ADDRESS_TYPE_STORE]);
  });

  const isClickAndCollectEligibleBasestore = computed(() => {
    const enabledBasestores = [
      config.public.baseStoreAt,
      config.public.baseStoreBe,
      config.public.baseStoreCh,
      config.public.baseStoreDe,
      config.public.baseStoreFr,
      config.public.baseStoreLu,
      config.public.baseStorePl,
    ];
    return enabledBasestores.includes(baseStore);
  });

  function baseStoreHasDeliveryMode(deliveryMode) {
    return deliveryModesMapping?.[deliveryMode]?.[baseStore];
  }

  return {
    deliveryTypeStationPerCountry,
    deliveryTypeStorePerCountry,
    deliveryModesMapping,
    isClickAndCollectEligibleBasestore,
    baseStoreHasDeliveryMode,
  };
}
