import { createCustomEvent } from '@/utils/events';

declare const window: Window & {
  mboxes: {
    items: any[];
    firstPageLoad: boolean;
    push: (item: any) => void;
    replace: (items: any[]) => void;
    clear: () => void;
    onChange: () => void;
    onPageLoaded: () => void;
    setOnChange: (onChange: () => void, immediate?: boolean) => void;
  };
};

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  window.mboxes?.setOnChange(() => {
    useMainStore().mboxes = [...window.mboxes.items];
  });

  nuxtApp.$router.afterEach(async () => {
    if (nuxtApp.isHydrating) {
      return;
    }

    window.mboxes?.onPageLoaded();

    document.body.dispatchEvent(createCustomEvent('nuxtRouteChange'));
  });
});
