// return these types: POS, EMPLOYEE, PET, CUSTOMER, CONTACTFORM, CONTACTFORMVIDEO, SALON
const UPLOAD_IMAGE_TYPES = {
  POS: 'POS',
  EMPLOYEE: 'EMPLOYEE',
  PET: 'PET',
  CUSTOMER: 'CUSTOMER',
  CONTACTFORM: 'CONTACTFORM',
  CONTACTFORMVIDEO: 'CONTACTFORMVIDEO',
  SALON: 'SALON',
};

export default UPLOAD_IMAGE_TYPES;
