/**
 * Extracts tests from a cookie object where filterEnabledTests indicates
 * whether to return enabled or disabled tests.
 */
const getTestsFromCookie = (abTestsCookie, filterEnabledTests) => {
  const tests = {};

  for (const [testName, testIsEnabled] of Object.entries(unref(abTestsCookie))) {
    if (!!testIsEnabled === filterEnabledTests) {
      tests[testName] = testIsEnabled;
    }
  }

  return Object.keys(tests)?.length ? tests : undefined;
};

/**
 * Enables or disables A/B tests in store.
 */
const handleAbTestsInStore = (store, abTests) => {
  for (const [testName, testIsEnabled] of Object.entries(abTests)) {
    store.abTests[testName] = testIsEnabled;
  }
};

/**
 * Handles A/B testing for pages within the nuxt /pages directory
 * and Vue components. It utilizes `this` for parameters when called from
 * a Vue component and utilizes parameters from asyncData when called from a page.
 */
export default function handleAbTests() {
  const mainStore = useMainStore();
  const abTestsCookie = useCookie('FN-UX');

  if (!unref(abTestsCookie) || mainStore.isAppview) {
    mainStore.setAbTests(undefined);
    return undefined;
  }

  const enabledAbTests = getTestsFromCookie(abTestsCookie, true);
  const disabledAbTests = getTestsFromCookie(abTestsCookie, false);

  handleAbTestsInStore(mainStore, { ...enabledAbTests, ...disabledAbTests });
  mainStore.setAbTests(enabledAbTests);
}
