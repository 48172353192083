import { stringify } from 'qs';

const getStringifiedObjectsParams = (params: Record<string, any>) => {
  const stringifiedParams = Object.keys(deepClone(params)).reduce(
    (acc: Record<string, any>, key: keyof typeof params) => {
      if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
        acc[key] = JSON.stringify(params[key]);
      } else {
        acc[key] = params[key];
      }
      return acc;
    },
    {},
  );
  return stringifiedParams;
};

const getRemovedEmptyParams = (params: Record<string, any>) => {
  const removedEmptyParams = Object.keys(deepClone(params)).reduce(
    (acc: Record<string, any>, key: keyof typeof params) => {
      if (
        params[key] !== null &&
        params[key] !== undefined &&
        params[key] !== '' &&
        params[key] !== 'undefined' &&
        params[key] !== 'null'
      ) {
        acc[key] = params[key];
      }
      return acc;
    },
    {},
  );
  return removedEmptyParams;
};

export const getSearchParamsSerializerResult = (params: Record<string, any>) => {
  const stringifiedParams = getStringifiedObjectsParams(params);
  const removedEmptyParams = getRemovedEmptyParams(stringifiedParams);
  return stringify(removedEmptyParams, { arrayFormat: 'brackets' });
};

export const getUrlWithoutDoubleSlashes = (url: string) => {
  return url.replace(/([^:]\/)\/+/g, '$1');
};
