import axios from 'axios';
import { getSearchParamsSerializerResult, getUrlWithoutDoubleSlashes } from '@/utils/axios/interceptors';

// Client-side Axios does not need httpsAgent.rejectUnauthorized, it is controlled by site cert trust settings
const axiosInstance = axios.create({
  paramsSerializer: {
    serialize: getSearchParamsSerializerResult,
  },
});

axiosInstance.interceptors.request.use(function (config) {
  // check request url and remove double slashes
  if (config.url) {
    config.url = getUrlWithoutDoubleSlashes(config.url);
  }
  return config;
});

export default defineNuxtPluginWithTiming(import.meta.url, () => {
  return {
    provide: {
      axios: axiosInstance,
    },
  };
});
