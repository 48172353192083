type GetCanonicalProps = {
  page: Record<string, any> | null;
  currentHost: string;
  robotsHasNoIndex: boolean;
};

export function useGetCanonical({ page, currentHost, robotsHasNoIndex }: GetCanonicalProps) {
  return computed<string | undefined>(() => {
    // Technically somewhat pointless to do, but we explicitly
    // omit canonical URLs if the page is noindex since like forever
    if (robotsHasNoIndex) {
      return undefined;
    }

    // FIXME: Function is called for *every* route, should this be a) done for all, b) omitted for all, c) mix-match?
    //  Followup: path vs fullPath in this case?
    // Remove query params and location hash parts
    // const path = unref(useRouter().currentRoute).fullPath?.split('?')[0]?.split('#')[0];
    const path = unref(useRouter().currentRoute).fullPath;
    const canonicalUrl = (page?.content?.meta?.canonical_url || path)?.trim();

    // Assuming schema-less URLs have no host
    if (canonicalUrl && !canonicalUrl.startsWith('http')) {
      return [currentHost, canonicalUrl].join('');
    }

    return canonicalUrl || undefined;
  });
}
