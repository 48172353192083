<template>
  <div id="fn-bonial-widget" />
</template>

<script>
export default {
  mounted() {
    const bonialLibrary = document.createElement('script');
    bonialLibrary.src = 'https://bonialconnect.com/v2/widget.js';
    document.body.appendChild(bonialLibrary);

    const waitInterval = setInterval(() => {
      if (!window.BonialServices) {
        return;
      }

      clearInterval(waitInterval);

      window.BonialServices.init('#fn-bonial-widget', {
        authKey: useRuntimeConfig().bonialAuthKey,
      });
    }, 100);
  },
};
</script>
