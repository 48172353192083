export function getRouteRedirectTo(to, { keepQuery = true, keepQueryOnlyAdobe = false } = {}) {
  if (!keepQuery || !to?.query || (keepQueryOnlyAdobe && !to?.query.adobe_mc)) {
    return to.path;
  }

  const formattedQuery = new URLSearchParams({
    ...to.query,
  });

  return `${to.path}?${formattedQuery.toString()}`;
}

export async function navigateToWithAdobeParam(path, query, options = { redirectCode: 303 }) {
  return navigateTo(getRouteRedirectTo({ path, query }, { keepQueryOnlyAdobe: true }), options);
}
