import { getDomainsByBaseStore, getIsocodesByLocale } from '@/utils/baseStores';

const getProtocol = (config = useRuntimeConfig()) => {
  return config.public.httpsEnabled || config.public.httpsProxyEnabled ? 'https' : 'http';
};

export default defineNuxtPluginWithTiming(import.meta.url, async (nuxtApp) => {
  const mainStore = useMainStore();

  const isocodes = getIsocodesByLocale(nuxtApp.$config);
  const domains = getDomainsByBaseStore(nuxtApp.$config);
  const domain = domains[mainStore.baseStore];
  const host = `${getProtocol()}://${domain}`;

  await nuxtApp.$i18n.setLocale(mainStore.language);

  return {
    provide: {
      isocodes,
      domains,
      domain,
      host,
    },
  };
});
