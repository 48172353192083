<template>
  <component :is="DynamicComponent">
    <slot />
  </component>
</template>

<script setup lang="ts">
const route = useRoute();

const DynamicComponent = computed(() => {
  switch (route.meta.layout) {
    case 'minimal':
      return defineAsyncComponent(() => import('@/components/organisms/page-container/PageContainerMinimal.vue'));

    case 'salon':
      return defineAsyncComponent(() => import('@/components/organisms/page-container/PageContainerSalon.vue'));

    default:
      return defineAsyncComponent(() => import('@/components/organisms/page-container/PageContainerDefault.vue'));
  }
});
</script>
