const CACHE_KEY_JOIN = '=';
const CACHE_KEY_DELIMITER = '|';

const entryHasValue = (entry) =>
  typeof entry[1] !== 'undefined' && entry[1] !== null && entry[1] !== '' && entry[1]?.length !== 0;
const entryAsString = (entry) => `${entry[0]}${CACHE_KEY_JOIN}${entry[1]}`;

export const generateCacheKey = (custom = {}) => {
  const data = {
    ...custom,
  };

  // Add required cache keys
  return Object.entries(data).filter(entryHasValue).map(entryAsString).join(CACHE_KEY_DELIMITER);
};

/**
 * Generate a consistent cache key using common parameters.
 */
export const generateCacheKeyPage = (language, baseStore, fullPath, page, custom = {}) =>
  generateCacheKey({
    id: page?.wordPressPageId,
    rev: page?.revisionId,
    template: page?.content?.template,
    baseStore,
    fullPath,
    language,
    ...custom,
  });

export default {
  generateCacheKeyPage,
};
