import type { BaseStoreConfig, BaseStoreKeys } from '@/utils/baseStoreConfig';

export type ABTests = {
  searchhub: boolean;
  tests: null | string;
  UXR72DeliveryCost: boolean;
};

export type PDPBreadcrumbs = {
  nodes: any[];
};

export type Progress = {
  activeStep: number;
  steps: number;
};

export type VoucherAdded = {
  status: boolean | 'loading';
  error?: { type: string };
} | null;

export type Country = any;
export type BillingCountry = any & Country;
export type ShippingCountry = any & Country;

export type ContactReasonValidate = {
  resolve: typeof Promise.resolve;
  reject: typeof Promise.reject;
};

export type MainState = {
  abTests: ABTests;
  accountNodes: any[];
  baseStore: BaseStoreKeys;
  baseStoreConfig: Partial<BaseStoreConfig>;
  billingCountries: BillingCountry[];
  configuration: any;
  contactReasonExist: boolean;
  contentTiles: any[];
  defaultCountry: string;
  devicePlatform: string | null;
  friendsBannerClosed: boolean;
  smartBannerClosed: boolean;
  campaignHeaderClosed: boolean;
  emergencyHeaderClosed: boolean;

  error: any | null;
  errorPage: any | null;
  features: any;
  flexibleFooter: any | null;
  flexibleHeader: any | null;
  flexibleLayoutType: any | null;
  footer: any | null;
  header: any | null;
  headerHeight: number;
  optionalHeadersHeight: number;
  hrefLang: any | null;
  iframeModalUrl: string | null;
  isMultilang: boolean;
  language: string;

  /**
   * Global page loading state.
   */
  isLoading: boolean;
  firstLoad: boolean;

  localeDomains: typeof localeDomains;
  mboxes: any[];

  loadedPage: any;
  pageTemplate: string;
  pdpBreadcrumbs: PDPBreadcrumbs;
  pdpSearchQuery: string;
  preservedEmail: string;
  progress: Progress;
  salesDisabled: boolean;
  searchedStores: any | null;
  searchTerm: any | null;
  sectionSmileHeight: number;
  shippingCountries: ShippingCountry[];
  voucherAdded: VoucherAdded;

  // Nuxt3 additions below
  isAppview: boolean;
  isMobileView: boolean;
  isRootFocus: boolean;
  contactReasonValidate: ContactReasonValidate | null;
};

function useGetFeature(state: any, name: string) {
  if (state.features?.featureToggles) {
    return !!state.features.featureToggles.find((feature: any) => feature.code === name && feature.active);
  }
  return false;
}

export const useMainStore = defineStore('main', {
  state: () =>
    ({
      abTests: {
        searchhub: false,
        tests: null,
        UXR72DeliveryCost: false,
      },
      accountNodes: [],
      baseStore: null,
      baseStoreConfig: {} as BaseStoreConfig,
      billingCountries: [],
      configuration: null,
      contactReasonExist: false,
      contentTiles: [],
      defaultCountry: 'DE',
      devicePlatform: null,

      // Header state
      friendsBannerClosed: false,
      smartBannerClosed: false,
      campaignHeaderClosed: false,
      emergencyHeaderClosed: false,

      error: null,
      errorPage: null,
      features: null,
      flexibleFooter: null,
      flexibleHeader: null,
      flexibleLayoutType: null,
      footer: null,
      header: {},
      headerHeight: 110,
      optionalHeadersHeight: 0,
      hrefLang: null,
      iframeModalUrl: null,
      isMultilang: false,
      language: '',

      isLoading: false,
      firstLoad: true,

      localeDomains,
      mboxes: [],
      pageTemplate: 'default',
      pdpBreadcrumbs: {
        nodes: [],
      },
      pdpSearchQuery: '',
      preservedEmail: '',
      progress: {
        activeStep: 0,
        steps: 0,
      },
      salesDisabled: false,
      searchedStores: null,
      searchTerm: null,
      sectionSmileHeight: 0,
      shippingCountries: [],
      voucherAdded: null,

      // Nuxt3 additions below
      isAppview: false,
      isMobileView: false,
      loadedPage: null,
      isRootFocus: false,
      contactReasonValidate: null,
    }) as MainState,
  getters: {
    getBaseStoreConfig(state) {
      return state.baseStoreConfig;
    },

    getIsPaybackInternationalApi(state) {
      return state.configuration?.paybackApi === 'INT';
    },

    getMinimumOrderValue(state) {
      return state.configuration?.minimumOrderValue;
    },

    getConfigPaymentModes(state) {
      return state.configuration?.paymentModes;
    },

    getPaybackAuthLogoutUrl(state) {
      return state.configuration?.paybackAuthLogoutUrl;
    },

    getShippingFee(state) {
      return state.configuration?.shippingFee;
    },

    getPaybackEnabled(state) {
      return state.configuration?.paybackEnabled;
    },

    getPaybackRedemptionEnabled(state) {
      return state.configuration?.paybackEnabled && state.configuration?.paybackRedemptionEnabled;
    },

    getPaybackRedemptionEnabledForApp(state) {
      return state.configuration?.paybackEnabled && state.configuration?.paybackRedemptionEnabledForApp;
    },

    getUrlPrefix(state) {
      return state.language && state.configuration?.language?.available?.length > 1
        ? `/${state.configuration?.language.available.find((lang: any) => lang.locale === state.language).isocode}`
        : null;
    },

    getShippingCountry: (state) => (countryIsocode: any) => {
      const isoLowerCase = countryIsocode.toLowerCase();
      return state.shippingCountries.find((shippingCountry) => shippingCountry.isocode.toLowerCase() === isoLowerCase);
    },

    getIsShippingCountry: (state) => {
      return (countryIsocode: any) => !!state.getShippingCountry(countryIsocode);
    },

    getBillingCountry(state) {
      return (countryIsocode: string) => {
        const isoLowerCase = countryIsocode.toLowerCase();
        return state.billingCountries.find((billingCountry) => billingCountry.isocode.toLowerCase() === isoLowerCase);
      };
    },

    getIsMultilang(state) {
      return state.configuration && state.configuration?.language && state.configuration?.language.available.length > 1;
    },

    getAvailableLanguages(state) {
      return state.configuration?.language?.available;
    },

    getMbox: (state: MainState) => (id: any) =>
      state.mboxes?.find((mbox) => {
        return mbox.id === id;
      }),

    getFreeShippingThreshold(state) {
      return state.configuration?.freeShippingThreshold;
    },

    getFeature(state) {
      return (name: string) => useGetFeature(state, name);
    },

    getSalonPriceItemsNewEnabled(state) {
      return useGetFeature(state, 'usingNewSalonPriceItemsEnabled');
    },

    getKittenclub(state): any {
      return useGetFeature(state, 'Kittenclub');
    },

    getClubEnabled(state): any {
      return useGetFeature(state, 'ClubEnabled');
    },

    getLegacyContact(state): any {
      return useGetFeature(state, 'legacyContact');
    },

    getEcomEnabledApp(state): any {
      return useGetFeature(state, 'ecomEnabledApp');
    },

    getCuralateGalleryEnabled(state): any {
      return useGetFeature(state, 'curalateGalleryEnabled');
    },

    getCuralateGalleryLazyLoad(state): any {
      return useGetFeature(state, 'curalateGalleryLazyLoad');
    },

    getClickAndReserve(state): any {
      return useGetFeature(state, 'clickAndReserve');
    },

    getFriendsBonus(state): any {
      return useGetFeature(state, 'friendsBonus');
    },

    getSalonBookingEnabled(state): any {
      return useGetFeature(state, 'salonBookingEnabled');
    },

    getNewPaybackIntegration(state: MainState) {
      return state.configuration.paybackApi === 'PBI';
    },

    getAllComponentsInAppEnabled(state): any {
      return useGetFeature(state, 'allComponentsInAppEnabled');
    },

    getEmailChangeEnabled(state): any {
      return useGetFeature(state, 'emailChangeEnabled');
    },

    getLoginRecaptchaEnabled(state): any {
      return useGetFeature(state, 'loginRecaptchaEnabled');
    },

    getRegistrationRecaptchaEnabled(state): any {
      return useGetFeature(state, 'registrationRecaptchaEnabled');
    },

    getNewsletterRecaptchaEnabled(state): any {
      return useGetFeature(state, 'newsletterRecaptchaEnabled');
    },

    getContactRecaptchaEnabled(state): any {
      return useGetFeature(state, 'contactRecaptchaEnabled');
    },

    getVotingRecaptchaEnabled(state): any {
      return useGetFeature(state, 'votingRecaptchaEnabled');
    },

    getForgotPasswordRecaptchaEnabled(state): any {
      return useGetFeature(state, 'forgotPasswordRecaptchaEnabled');
    },

    getEmailChangeRecaptchaEnabled(state): any {
      return useGetFeature(state, 'emailChangeRecaptchaEnabled');
    },

    getClickAndReserveRecaptchaEnabled(state): any {
      return useGetFeature(state, 'clickAndReserveRecaptchaEnabled');
    },

    getRaffleRecaptchaEnabled(state): any {
      return useGetFeature(state, 'raffleRecaptchaEnabled');
    },

    getPaypalReferenceTransactionsEnabled(state): any {
      return useGetFeature(state, 'paypalReferenceTransactions');
    },

    getPetmojiEnabled(state): any {
      return useGetFeature(state, 'petmojiEnabled');
    },

    getSovendusEnabled(state): any {
      return useGetFeature(state, 'sovendusEnabled');
    },

    getSpeedkitEnabled(state): any {
      return useGetFeature(state, 'speedkitEnabled');
    },

    getShowFreeShipping(state): any {
      return useGetFeature(state, 'showFreeShipping');
    },

    getShowOPPSpromotionToast(state) {
      return useGetFeature(state, 'showOPPSpromotionToast');
    },

    getSovidoEnabled(state): any {
      return useGetFeature(state, 'Kittenclub');
    },

    getCcaSubscriptionApiEnabled(state): any {
      return useGetFeature(state, 'ccaSubscriptionApiEnabled');
    },

    showOPPSpromotionToast(state): any {
      return useGetFeature(state, 'showOPPSpromotionToast');
    },

    getClickAndCollectEnabled(state): any {
      return useGetFeature(state, 'clickAndCollectEnabled');
    },

    getPickUpStationEnabled(state): any {
      return useGetFeature(state, 'pickUpStationEnabled');
    },

    clickAndCollectRolloutStoresBehaviourEnabled(state): any {
      return useGetFeature(state, 'clickAndCollectRolloutStoresBehaviourEnabled');
    },

    getFilteredAccountNodes(state: MainState) {
      if (!state.accountNodes?.length) {
        return [];
      }

      const userStore = useUserStore();
      const isShelterAccount = (userStore.user as any)?.isShelterAccount;
      return state.accountNodes.filter((node: any) => {
        const isHiddenForShelters = isShelterAccount && node.categories?.includes('shelter-only-menu-hidden');
        const isHiddenForNotShelter = !isShelterAccount && node.categories?.includes('shelter-only-menu-relevant');
        return !(isHiddenForShelters || isHiddenForNotShelter);
      });
    },

    getIsBaseStoreDK(state) {
      const config = useRuntimeConfig();
      return state.baseStore === (config.public.baseStoreDk || 'maxizooDK');
    },

    getIsBaseStoreFR(state) {
      const config = useRuntimeConfig();
      return state.baseStore === (config.public.baseStoreFr || 'maxizooFR');
    },

    getProgressBarSteps(state) {
      return state.progress.steps;
    },

    getProgressBarActiveSteps(state) {
      return state.progress.activeStep;
    },
  },
  actions: {
    async loadConfiguration() {
      const nuxtApp = useNuxtApp();

      nuxtApp.$log.debug('Loading configuration...');
      const response = await nuxtApp.$api.hybris.configuration.global();
      this.configuration = response.data;
    },

    async loadContentTiles() {
      const nuxtApp = useNuxtApp();

      nuxtApp.$log.debug('Loading content tiles...');
      const response = await nuxtApp.$api.hybris.contentTiles.get('DEFAULT', useMainStore().language);
      this.contentTiles = response.data.map((tile: any) => JSON.parse(tile.content).fields);
    },

    async loadFeatureToggles() {
      const nuxtApp = useNuxtApp();

      nuxtApp.$log.debug('Loading feature toggles...');
      const response = await nuxtApp.$api.hybris.configuration.features();
      this.features = response.data;
    },

    async loadHeader() {
      const { data } = await useNuxtApp().$api.getHeader();
      this.header = deepClone(data.content ?? {});
    },

    async loadFooter() {
      const { data } = await useNuxtApp().$api.getFooter();
      this.footer = deepClone(data.content ?? {});
    },

    /**
     * Fire and forget page loading function, used to retrieve page content from our CMS
     * @since: Nuxt3
     */
    async loadPage(requestedPageUrl: string) {
      const { $api, $router } = useNuxtApp();

      await $router.isReady();

      // String language prefix
      let pageUrl = removeLanguagePrefix(requestedPageUrl);

      // Custom per-page URL mapping
      if (pageUrl === '/checkout/start/') {
        pageUrl = '/checkout/login';
      } else if (pageUrl.startsWith('/stores/') && pageUrl.split('/').length === 4) {
        pageUrl = '/storefinder/detail';
      } else if (pageUrl.startsWith('/p/') && pageUrl.split('/').length === 4) {
        pageUrl = '/p/product-detail';
      }

      this.loadedPage = await $api.getPage(encodeURI(pageUrl));
      this.pageTemplate = this.loadedPage?.content?.template;

      return this.loadedPage || false;
    },

    setError({ code, image, title, text, button, redirectUrl }: any) {
      this.error = {
        code,
        image,
        title,
        text,
        button,
        redirectUrl,
      };
    },

    clearError() {
      this.error = null;
    },

    startLoading() {
      this.isLoading = true;
    },

    finishLoading() {
      this.isLoading = false;
    },

    setProgressBarSteps(steps: any) {
      this.progress.steps = steps;
    },

    setProgressBarActiveSteps(steps: any) {
      this.progress.activeStep = steps;
    },

    updateDevicePlatform({ userAgent }: { userAgent: string }) {
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        this.devicePlatform = 'ios';
      } else if (/Android/i.test(userAgent)) {
        this.devicePlatform = 'android';
      } else {
        this.devicePlatform = null;
      }
    },

    setContactReasonExist(contactReasonExist = false) {
      this.contactReasonExist = contactReasonExist;
    },

    setAbTests(abTests: any) {
      this.abTests.tests = abTests ? JSON.stringify(abTests) : null;
    },

    async getTopCategories(tealiumCategories: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting personalized categories...');

      const categories: any[] = [];
      Object.keys(tealiumCategories).forEach((code, count) => {
        categories.push({ count, code });
      });

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.personalization
          .getTopCategories(categories, this.language)
          .then(async (response: any) => {
            nuxtApp.$log.debug('Personalized categories load successful.');

            return resolve(response.data.categories);
          })
          .catch(async (error: any) => {
            nuxtApp.$log.error('Error while getting personalized categories.', error);

            return reject(error);
          }),
      );
    },

    loadBillingCountries() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Loading billing countries...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.country
          .billing(this.language)
          .then((response: any) => {
            nuxtApp.$log.debug('Billing countries loaded.');

            this.billingCountries = response.data.countries;

            return resolve(false);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while loading billing countries.', error);

            return reject(error);
          }),
      );
    },

    loadShippingCountries() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Loading shipping countries...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.country
          .shipping(this.language)
          .then((response: any) => {
            nuxtApp.$log.debug('Shipping countries loaded.');

            this.shippingCountries = response.data.countries;

            return resolve(undefined);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while loading shipping countries.', error);

            return reject(error);
          }),
      );
    },

    uploadFile({ data, type }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Adding file...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.file
          .create(data, type)
          .then((response: any) => {
            nuxtApp.$log.debug('File added.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while adding file.', error);

            if (error.response) {
              nuxtApp.$errorHandler.handleErrorModal(error, false);
            }

            return reject(error);
          }),
      );
    },

    createShelterImage(data: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Adding image file...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.file
          .create(data, 'CUSTOMER')
          .then((response: any) => {
            nuxtApp.$log.debug('Image added.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while adding image file.', error);

            if (error.response) {
              nuxtApp.$errorHandler.handleErrorModal(error, false);
            }

            return reject(error);
          }),
      );
    },

    loadCmsErrorPage() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Loading 404 Error-Page...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api
          .getPage('error', true)
          .then((page: any) => {
            nuxtApp.$log.debug('404 Error-Page loaded.');

            this.errorPage = page;
            return resolve(page);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while loading 404 Error Page.', error);

            return reject(error);
          }),
      );
    },

    loadAccountNodes() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Loading account navigation nodes...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api
          .getNodes('/my-account/')
          .then((response: any) => {
            nuxtApp.$log.debug('Account nodes loaded.');
            this.accountNodes = response.nodes || [];
            return resolve(this.accountNodes);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while loading account navigation nodes.', error);

            return reject(error);
          }),
      );
    },

    getSearchTermSuggestions({ searchTerm }: { searchTerm: string | null }): Promise<Record<string, any>[]> {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug(`Getting search term suggestions with SmartSearch enabled: ${this.abTests.searchhub}...`);

      let apiCall: any;
      if (searchTerm) {
        apiCall = nuxtApp.$api.hybris.product.suggestions(searchTerm, null, this.language, this.abTests);
      } else {
        apiCall = nuxtApp.$api.hybris.product.topSearchPhrases(this.language);
      }

      return new Promise((resolve, reject) =>
        apiCall
          .then((response: any) => {
            nuxtApp.$log.debug('Got search term suggestions.');

            const data = response.data;
            return resolve(data.suggestions || data.topSearchPhrases);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting search term suggestions.', error);

            return reject(error);
          }),
      );
    },

    async getProductSuggestions({ searchTerm }: { searchTerm: string | null }): Promise<Record<string, any>> {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug(`Getting product suggestions with SmartSearch enabled: ${this.abTests.searchhub}...`);

      let searchIds: string[] | null = null;
      let abTests = { ...this.abTests } as Record<string, any> | null;

      if (!searchTerm) {
        const topSellers = await nuxtApp.$api.hybris.product.topSellers(5, this.language);
        searchIds = topSellers.data.map((product: Record<string, any>) => product.code).join(',');
        abTests = null;
      }

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.product
          .search(
            searchTerm,
            'relevance',
            0,
            null,
            searchIds,
            this.language,
            null,
            null,
            'DEFAULT',
            5,
            null,
            [],
            abTests,
          )
          .then((response: any) => {
            nuxtApp.$log.debug('Got product suggestions.');

            const data = response.data;

            return resolve({
              products: data.products,
              categories: data.facets?.find((facet: Record<string, any>) => facet.code === 'category')?.nodes,
            });
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting product suggestions.');
            nuxtApp.$log.error(error);

            return reject(error);
          }),
      );
    },

    saveContactForm({ formId, fields }: { formId: string | number; fields: any }) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Saving contact form...');

      const { recaptchaResponse } = fields;

      // remove ReCaptcha from fieldValues - for nuxtApp OCC expected outside the object
      delete fields.recaptchaResponse;
      delete fields.validateRecaptcha;

      const entry: any[] = [];
      Object.entries(fields).forEach(([key, value]) => {
        entry.push({ key, value });
      });

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.contactForm
          .save(formId, entry, recaptchaResponse, this.language)
          .then((response: any) => {
            nuxtApp.$log.debug('Contact form saved.');

            return resolve(response);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while saving contact form.', error);

            return reject(error);
          }),
      );
    },

    saveContactFileForm({ formId, fields }: { formId: string | number; fields: any }) {
      const nuxtApp = useNuxtApp();
      // @ts-ignore
      const { getType: userType } = useUserStore();
      nuxtApp.$log.debug('Saving contact form for authenticated user...');

      const { files } = fields;
      delete fields.files;

      const dtoString = {
        formId,
        fieldValues: {
          ...fields,
        },
        recaptchaResponse: fields.recaptchaResponse ?? null,
      };

      // remove ReCaptcha from fieldValues - for this OCC expected outside the object
      delete dtoString.fieldValues.recaptchaResponse;

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.contactForm
          .saveWithFiles(userType, dtoString, files, this.language)
          .then((response: any) => {
            nuxtApp.$log.debug('Contact form with files saved.');

            return resolve(response);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while saving contact form with files.', error);

            return reject(error);
          }),
      );
    },

    registerRaffleEntry(payload: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Register raffle entry...');
      // @ts-ignore
      const { getType: userType } = useUserStore();
      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.raffle
          .register(userType, payload)
          .then((response: any) => {
            nuxtApp.$log.debug('Registered raffle entry...');
            return resolve(response);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while registering raffle entry.', error);

            return reject(error);
          }),
      );
    },

    getSeoStoreList(): Promise<Record<string, any>[]> {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting SEO stores...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.store
          .getSeoStores(this.language)
          .then((response: any) => {
            nuxtApp.$log.debug('Got SEO stores.');

            return resolve(response.data.stores);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting SEO stores.', error);

            return reject(error);
          }),
      );
    },

    getRegionsStoresList() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting stores grouped by region...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.store
          .getRegionsStores(this.language)
          .then((response: any) => {
            nuxtApp.$log.debug('Got region stores.');

            return resolve(response.data.regionStores);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting region stores.', error);

            return reject(error);
          }),
      );
    },

    getStoreList({ query, latitude, longitude, currentPage, onlySalons, radius }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting stores...');
      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.store
          .all(query, latitude, longitude, currentPage, this.language, onlySalons, radius)
          .then((response: any) => {
            nuxtApp.$log.debug('Got stores.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting stores.', error);

            return reject(error);
          }),
      );
    },

    getStoreListSorted({ query, latitude, longitude, currentPage, onlySalons, radius, sort, productId }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting stores...');
      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.store
          .getProductAvailability(
            query,
            latitude,
            longitude,
            currentPage,
            this.language,
            onlySalons,
            radius,
            sort,
            productId,
          )
          .then((response: any) => {
            nuxtApp.$log.debug('Got stores.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting stores.', error);

            return reject(error);
          }),
      );
    },

    getStoreListFiltered({ query, latitude, longitude, radius, filterProperties }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting filtered stores...');
      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.store
          .allFiltered(query, latitude, longitude, this.language, radius, filterProperties)
          .then((response: any) => {
            nuxtApp.$log.debug('Got filtered stores.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while getting filtered stores.', error);

            return reject(error);
          }),
      );
    },

    async getStoreLocation(location: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Find store by location...');
      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.store
          .getStoreLocation(location)
          .then((response: any) => {
            nuxtApp.$log.debug('Stores found...');
            this.searchedStores = response.data;
            return resolve(this.searchedStores);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while searching for stores', error);

            return reject(error);
          }),
      );
    },

    deleteFile(mediaId: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Delete file...');

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.file
          .delete(mediaId)
          .then((response: any) => {
            nuxtApp.$log.debug('File deleted.');

            return resolve(response);
          })
          .catch((error: any) => {
            nuxtApp.$log.error('Error while deleting file.', error);

            if (error.response) {
              nuxtApp.$errorHandler.handleErrorModal(error, false);
            }

            return reject(error);
          }),
      );
    },

    async loadStoresSelectables() {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting all stores selectable values...');
      const userStore = useUserStore();

      try {
        const response = await nuxtApp.$api.hybris.store.getStoresSelectables();
        userStore.storesSelectables = response.data;
      } catch (error) {
        nuxtApp.$log.error('Error while searching for stores', error);
      }
    },

    bookReservation({ payload, serviceProviderType }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Sending booking request...');
      // @ts-ignore
      const { getType: userType } = useUserStore();

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.booking
          .bookReservation(userType, payload, serviceProviderType)
          .then(async (response: any) => {
            nuxtApp.$log.debug('Booking request accepted.');

            return resolve(response);
          })
          .catch(async (error: any) => {
            nuxtApp.$log.error('Error while booking.', error);

            nuxtApp.$errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    getAvailableTimeSlots({ payload, serviceProviderType }: any): Promise<any> {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting timeslots...');
      // @ts-ignore
      const { getType: userType } = useUserStore();
      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.booking
          .getAvailableTimeslots(userType, payload, serviceProviderType)
          .then(async (response: any) => {
            nuxtApp.$log.debug('Received available time slots.');
            return resolve(response);
          })
          .catch(async (error: any) => {
            nuxtApp.$log.error('Error while getting time slots.', error);

            nuxtApp.$errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    getTreatments({ serviceProviderType }: any) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Getting treatments...');
      const userType = useUserStore().getType;
      return new Promise((resolve, reject) =>
        $api.hybris.booking
          .getTreatments(userType, serviceProviderType)
          .then(async (response: any) => {
            $log.debug('Received available treatments.');
            return resolve(response);
          })
          .catch(async (error: any) => {
            $log.error('Error while getting treatments.');
            $log.error(error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    getStoreById({ id }: any) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Getting store by id...');
      return new Promise((resolve, reject) =>
        $api.hybris.store
          .find(id)
          .then((response: any) => {
            $log.debug('Got store.');
            return resolve(response.data);
          })
          .catch((error: any) => {
            $log.error('Error while getting store by id.');
            $log.error(error);

            return reject(error);
          }),
      );
    },

    setHeaderClosed(headerType: string) {
      if (headerType in this) {
        // @ts-ignore
        this[headerType] = true;
      }
    },

    closeHeader({ headerType, days = 0 }: any) {
      const key = `${headerType}Closed`;

      // Update state to make closing reactive
      this.setHeaderClosed(key);

      if (days > 0) {
        // Update cookie to persist closing
        const cookie = useCookie(key, {
          expires: addDaysToNow(days),
        });
        cookie.value = '1';
      }
    },

    cancelReservation({ reservationId, serviceProviderType }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Sending cancellation booking request...');
      // @ts-ignore
      const { getType: userType } = useUserStore();

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.booking
          .cancelReservation(userType, reservationId, serviceProviderType, this.language)
          .then(async (response: any) => {
            nuxtApp.$log.debug('Booking cancellation successful.');

            return resolve(response);
          })
          .catch(async (error: any) => {
            nuxtApp.$log.error('Error while cancelling booking.', error);

            nuxtApp.$errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    getSheltersList({ currentPage, pageSize }: any) {
      const nuxtApp = useNuxtApp();
      nuxtApp.$log.debug('Getting shelters list...');
      const lang = this.language;

      return new Promise((resolve, reject) =>
        nuxtApp.$api.hybris.shelters
          .all(currentPage, pageSize, lang)
          .then(async (response: any) => {
            nuxtApp.$log.debug('Shelters list load successful.');

            return resolve(response.data);
          })
          .catch(async (error: any) => {
            nuxtApp.$log.error('Error while getting shelters list.', error);

            return reject(error);
          }),
      );
    },

    async nuxtServerInit() {
      const nuxtApp = useNuxtApp();

      // Add extra check to prevent breaking when refactoring since init is called as early as possible
      if (import.meta.dev && !nuxtApp.$api) {
        throw createError({
          statusCode: 500,
          statusMessage: `This action depends on 'plugins/05.api', ensure correct initialization.`,
          fatal: true,
        });
      }

      await this.loadConfiguration();

      // For cacheable pages, load without user, so no user information gets into the cache
      useUserStore().loadingWasPrevented = false;
      await this.init({
        domain: nuxtApp.$domain,
        withUser: true,
      });
    },

    async init({ domain, withUser, preventSoftLoginModal }: any): Promise<any> {
      const nuxtApp = useNuxtApp();
      useDatalayerStore().initParams.withUser = withUser;

      // Add extra check to prevent breaking when refactoring since init is called as early as possible
      if (import.meta.dev && !nuxtApp.$api) {
        throw createError({
          statusCode: 500,
          statusMessage: `This action depends on 'plugins/05.api', ensure correct initialization.`,
          fatal: true,
        });
      }

      const mainStore = useMainStore();
      const userStore = useUserStore();
      const wishlistStore = useWishlistStore();
      const datalayerStore = useDatalayerStore();

      withUser && (await userStore.initializeToken());
      await this.loadFeatureToggles();

      const analyticsResourceList = [resourceNames.USER, resourceNames.CART, resourceNames.WISHLIST];

      const results = await Promise.allSettled([
        withUser && userStore.getIsAuthenticated
          ? userStore.loadUser({ preventSoftLoginModal, analyticsResourceList })
          : null,
        withUser && wishlistStore.initializeWishlist(),
        this.loadHeader(),
        this.loadFooter(),
        withUser && userStore.getIsAuthenticated ? this.loadAccountNodes() : null,
        withUser && !userStore.getIsAuthenticated ? datalayerStore.loadAnalytics() : null,
        this.loadContentTiles(),
      ]);

      const error = (results.find((p) => p.status === 'rejected') as PromiseRejectedResult)?.reason;
      if (error) {
        // Soft login
        if (isResponseError(error, 401, REQUIRES_HARD_LOGIN_ERROR)) {
          userStore.softLogin = true;
          userStore.setNameFromCookie();
          return;
        }

        // Invalid token
        if (isResponseError(error, 401, INVALID_TOKEN_ERROR)) {
          await this.resetLogout({ reason: 'Invalid token' });
          return this.init({ domain, withUser });
        }

        // Maintenance mode
        if (isResponseError(error, 503)) {
          return navigateTo(`https://${domain}/maintenance/`);
        }

        throw error;
      }

      mainStore.updateDefaultCountryInStore();
    },

    /**
     * @param invalidToken Skip logout API call if defined
     * @param reason Additional reset reason which is added to logs
     */
    async resetLogout({ invalidToken, reason }: { invalidToken?: string; reason?: string } = {}) {
      const nuxtApp = useNuxtApp();
      const userStore = useUserStore();
      const wishlistStore = useWishlistStore();
      const datalayerStore = useDatalayerStore();
      const cartStore = useCartStore();
      const newsletterStore = useNewsletterStore();

      nuxtApp.$log.debug(`Resetting application... ${reason ? ` (${reason})` : ''}`);

      try {
        await userStore.logout(invalidToken);
      } catch (error) {
        // We had infinite loops during the early migration phase here,
        // this should no longer be possible still logging just in case
        nuxtApp.$log.error(error);
        return false;
      }

      // Reset stores
      datalayerStore.cart = null;
      datalayerStore.bvInformation = null;
      datalayerStore.user = null;
      datalayerStore.wishlist = null;
      newsletterStore.$reset();
      wishlistStore.$reset();
      cartStore.$reset();
      userStore.$reset();

      useDeleteCookies([
        'appview',
        'cart',
        'cart-payment',
        'campaignHeaderClosed',
        'cclattr',
        'FN-UX',
        'friendsBannerClosed',
        'no-adobe',
        'smartbanner_exited',
        'token',
        'uid',
      ]);

      deleteFromLocalStorage(LOCAL_STORAGE_ADYEN_PAYLOAD);
      deleteFromLocalStorage(LOCAL_STORAGE_ADYEN_ACTION);
      deleteFromLocalStorage(LOCAL_STORAGE_DELIVERY_OPTION);
      deleteFromLocalStorage(LOCAL_STORAGE_PICKUP_DELIVERY_ADDRESS);

      notifyApp('onLogout');

      nuxtApp.$sentry.setUser(null);

      nuxtApp.$log.debug('Finished resetting application.');

      return true;
    },

    updateDefaultCountryInStore() {
      this.defaultCountry =
        this.baseStoreConfig.defaultCountry || this.baseStore?.slice(-2) || this.language.toUpperCase();
    },

    // Used to silence TS error for mapActions
    reset() {
      this.$reset();
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot));
}
