import {
  SHIPPING_DURATION_REPLENISHMENT_EARLY,
  SHIPPING_DURATION_REPLENISHMENT_LATE,
  SHIPPING_DURATION_REPLENISHMENT_MEDIUM,
} from './shipping';

export const STOCK_LEVEL_STATUS_INSTOCK = 'inStock';
export const STOCK_LEVEL_STATUS_LOWSTOCK = 'lowStock';
export const STOCK_LEVEL_STATUS_OUTOFSTOCK = 'outOfStock';

export const inStock = stockLevelStatus =>
  stockLevelStatus === STOCK_LEVEL_STATUS_INSTOCK || stockLevelStatus === STOCK_LEVEL_STATUS_LOWSTOCK;

export const onLowStock = stockLevelStatus => stockLevelStatus === STOCK_LEVEL_STATUS_LOWSTOCK;

export const isOutOfStock = stockLevelStatus => stockLevelStatus === STOCK_LEVEL_STATUS_OUTOFSTOCK;

export const getNotInStockErrorI18n = stockLevelStatus => {
  if (stockLevelStatus === SHIPPING_DURATION_REPLENISHMENT_EARLY) {
    return 'shipping.status.shortlyAvailable';
  }
  if (stockLevelStatus === SHIPPING_DURATION_REPLENISHMENT_MEDIUM) {
    return 'shipping.status.currentlyNotAvailable';
  }
  if (stockLevelStatus === SHIPPING_DURATION_REPLENISHMENT_LATE) {
    return 'shipping.status.notAvailable';
  }
  if (stockLevelStatus === STOCK_LEVEL_STATUS_OUTOFSTOCK) {
    return 'shipping.status.outOfStock';
  }

  return '';
};
