export default {
  methods: {
    async openAuthorization(number: any) {
      const { openPaybackAuthorizationUrl } = usePaybackStore();
      await openPaybackAuthorizationUrl({
        // @ts-ignore
        redirectPath: this.$t('url.paybackAuth'),
        paybackNumber: number,
      });
    },

    getPaybackFormErrors(error: any): any {
      if (error.response?.data?.errors?.[0]?.type === 'PaybackCardBlockedError') {
        return {
          // @ts-ignore
          paybackNumber: [this.$t('validation.paybackCardBlocked')],
        };
      }

      if (error.response?.data?.errors?.[0]?.reason === 'PAYBACK_NUMBER_USAGE_EXCEEDED') {
        return {
          // @ts-ignore
          paybackNumber: [this.$t('validation.PAYBACK_NUMBER_USAGE_EXCEEDED')],
        };
      }
      // @ts-ignore
      return this.$errorHandler.getFormErrors(error, this.errorMapping);
    },
  },
};
