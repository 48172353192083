import AbstractApi from '../AbstractApi';

export default class AuthApi extends AbstractApi {
  constructor(client, logger, config, $host) {
    super(client, logger, config);
    this.basePath = `/api/storefront/auth`;
    this.baseUrl = $host;
  }

  login(
    username,
    password,
    type = 'basic',
    cdcSynchronousFlows = false,
    baseSite = 'FressnapfDE',
    grantEcoConsent = false,
    recaptchaResponse = null,
  ) {
    const reqData = {
      username,
      password,
      type,
      cdcSynchronousFlows,
      baseSite,
      grantEcoConsent,
    };

    if (recaptchaResponse) {
      reqData.recaptchaResponse = recaptchaResponse;
    }

    const headers = {};

    if (process.env.NODE_ENV === 'development') {
      headers['X-Forwarded-For'] = '127.0.0.1';
    }

    return this.request(
      {
        method: 'post',
        url: '/login',
        data: {
          ...reqData,
        },
        headers,
      },
      false,
    );
  }

  logout(token) {
    return this.request({
      method: 'post',
      url: '/logout',
      data: {
        token,
      },
    });
  }

  anonymous() {
    return this.request({
      method: 'post',
      url: '/anonymous',
    });
  }

  refresh(refreshToken, type = 'basic') {
    return this.request({
      method: 'post',
      url: '/refresh',
      data: {
        refreshToken,
        type,
      },
    });
  }

  thhToken() {
    return this.request({
      method: 'post',
      url: '/thh',
    });
  }
}
