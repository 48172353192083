/**
 * @see https://confluence.fressnapf.de/display/MA/JavaScript+Interface+%7C+App
 */
export function notifyApp(changedEntity, entityParam = null) {
  if (!import.meta.client || (!window.FressnapfWebInterface && !window.webkit?.messageHandlers?.fressnapfJSHandler)) {
    return;
  }

  if (changedEntity === 'user') {
    if (typeof window.FressnapfWebInterface?.notifyUserChanged === 'function') {
      window.FressnapfWebInterface?.notifyUserChanged();
    }
    window.webkit?.messageHandlers?.fressnapfJSHandler?.postMessage({ command: 'notifyUserChanged' });
  }

  if (changedEntity === 'cart') {
    if (typeof window.FressnapfWebInterface?.notifyCartChanged === 'function') {
      window.FressnapfWebInterface?.notifyCartChanged();
    }
    window.webkit?.messageHandlers?.fressnapfJSHandler?.postMessage({ command: 'notifyCartChanged' });
  }

  if (changedEntity === 'startCheckout') {
    if (typeof window.FressnapfWebInterface?.startCheckout === 'function') {
      window.FressnapfWebInterface?.startCheckout();
    }
    window.webkit?.messageHandlers?.fressnapfJSHandler?.postMessage({ command: 'startCheckout' });
  }

  if (changedEntity === 'onCheckoutSuccessful') {
    if (typeof window.FressnapfWebInterface?.onCheckoutSuccessful === 'function') {
      window.FressnapfWebInterface?.onCheckoutSuccessful();
    }
    window.webkit?.messageHandlers?.fressnapfJSHandler?.postMessage({ command: 'onCheckoutSuccessful' });
  }

  if (changedEntity === 'onLogout') {
    if (typeof window.FressnapfWebInterface?.onLogout === 'function') {
      window.FressnapfWebInterface?.onLogout();
    }
    window.webkit?.messageHandlers?.fressnapfJSHandler?.postMessage({ command: 'onLogout' });
  }

  if (changedEntity === 'onNewToken') {
    const hasNewToken = entityParam?.access_token && entityParam.refresh_token;
    if (typeof window.FressnapfWebInterface?.onNewToken === 'function' && hasNewToken) {
      window.FressnapfWebInterface?.onNewToken(toRaw(entityParam));
    }
    window.webkit?.messageHandlers?.fressnapfJSHandler?.postMessage({ command: 'onNewToken' });
  }
}
