import { resourceNames } from '@/utils/analytics';

export const useDatalayerStore = defineStore('datalayer', {
  state: () => ({
    page: null as null | Record<string, any>,
    products: null as null | Record<string, any>[],
    cart: null as null | Record<string, any>,
    user: null as null | Record<string, any>,
    filter: null as null | Record<string, any>,
    search: null,
    wishlist: null,
    order: null,
    error: null,
    bvInformation: null as null | {
      nickname: string;
      bvEncryptionKeyId: string;
      bvEncryptedEmail: string;
    },
    storeFinder: null as Record<string, any> | null,
    initParams: {
      withUser: false,
      initialLoad: true,
    },
  }),
  getters: {},
  actions: {
    addPageProperties: function (properties: Record<string, any>) {
      this.page = {
        ...this.page,
        ...properties,
      };
    },
    addUserProperties: function (properties: Record<string, any>) {
      this.user = {
        ...this.user,
        ...properties,
      };
    },
    addCartProperties: function (properties: Record<string, any>) {
      this.cart = {
        ...this.cart,
        ...properties,
      };
    },
    addFilterProperties: function (properties: Record<string, any>) {
      this.filter = {
        ...this.filter,
        ...properties,
      };
    },
    loadOrderData: async function (code: string) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Loading order data for datalayer...');

      const userStore = useUserStore();
      const userType = userStore.getType;

      return $api.hybris.analytics
        .get(userType, null, code, [resourceNames.ORDER, resourceNames.USER])
        .then((response: Record<string, any>) => {
          this.addUserProperties(response.data.user);
          this.order = response.data.order;
          this.cart = null;
        })
        .catch((error: any) => {
          $log.error('Failed loading order data for datalayer.', error);
        });
    },
    loadBVInformation: async function () {
      const { $log, $api } = useNuxtApp();
      $log.debug('Loading BV-relevant info for datalayer...');

      const userStore = useUserStore();
      const userType = userStore.getType;

      return $api.hybris.user
        .getBVKeys(userType)
        .then((response: Record<string, any>) => {
          const user = (userStore.user ?? {}) as Record<string, any>;

          this.bvInformation = {
            nickname: `${user.defaultAddress.firstName} ${user.defaultAddress.lastName}`,
            bvEncryptionKeyId: response.data.bvEncryptionKeyId,
            bvEncryptedEmail: response.data.bvEncryptedEmail,
          };
        })
        .catch((error: any) => {
          $log.error('Failed loading BV-relevant data for datalayer.', error);
        });
    },
    loadAnalytics: async function (resourceList?: string[]) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Loading analytics data...');

      const { getType: userType } = useUserStore();
      const { getCartId: cartId } = useCartStore();

      return $api.hybris.analytics
        .get(userType, cartId, null, resourceList)
        .then((response: Record<string, any>) => {
          if (response.data.user) {
            this.addUserProperties(response.data.user);
          }
          if (response.data.cart) {
            this.addCartProperties(response.data.cart);
          }
          if (response.data.wishlist) {
            this.wishlist = response.data.wishlist;
          }
          if (response.data.order) {
            this.order = response.data.order;
          }
        })
        .catch((error: any) => {
          $log.error('Failed loading user data for datalayer.', error);
        });
    },
    setStoreFinderAnalytics: function (storeFinderData: Record<string, any>) {
      useNuxtApp().$log.debug('Setting storeFinder data for datalayer...');

      this.storeFinder = storeFinderData;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDatalayerStore, import.meta.hot));
}
