<template>
  <component :is="data.aspect_ratio ? 'fn-iframe-wrapper' : 'div'">
    <iframe
      loading="lazy"
      :class="iframeClass"
      :src="data.src"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      :width="data.width || '100%'"
      :height="data.height || '100%'"
    />
  </component>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
const props = withDefaults(defineProps<{ data: Record<string, any> }>(), {
  data: () => ({}),
});

const iframeClass = `iframe-${props.data.src.replace(/[^a-z0-9]/g, '')}`;
let dynamicMediaQueries = '';

Object.entries(IFRAME_BREAKPOINTS).forEach(([key, minWidth]) => {
  const height = props.data.breakpoints[key];
  if (height) {
    dynamicMediaQueries += `
      @media (min-width: ${minWidth}px) {
        .${iframeClass} { height: ${height}; }
      }
    `;
  }
});

useHead({
  style: [
    {
      children: dynamicMediaQueries,
    },
  ],
});
</script>
