<template>
  <suspense>
    <component
      v-bind="props"
      :is="DynamicComponent"
    />
  </suspense>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type: string;
    data: Record<string, any>;
    idPrefix?: string;
    pageMetrics?: Record<string, any>;
    transformation?: string;
    imageOptions?: Array<string>;
  }>(),
  {
    idPrefix: '',
    pageMetrics: () => ({}),
    transformation: '',
    imageOptions: () => [],
  },
);

const DynamicComponent = useComponentLoader({
  props,
  componentResolver: (name) => import(`./../components/Cms${name}.vue`) as Promise<Component>,
  componentNameOverrides: {
    image_text_panel: 'ErrorIntro',
    related_posts_table: 'LinkTeaserList',
    videos: 'VideoGallery',
    actionbox: 'ActionBox',
  },
});
</script>
