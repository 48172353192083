import handleAbTests from '@/utils/abTests';

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  if (useMainStore().isAppview) {
    return;
  }

  void repeatUntil(handleAbTests)
    .then((abTests) => {
      nuxtApp.$log.debug('A/B tests loaded:', abTests);
    })
    .catch((error) => {
      if (error) {
        nuxtApp.$log.error('Failed to load A/B tests:', error);
      }
    });
});
