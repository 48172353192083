import { PAYMENT_MODE_BANK, PAYMENT_MODE_INVOICE } from '@/utils/payment-modes';

export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const config = useRuntimeConfig();

  const { baseStore } = useMainStore();
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const { query } = to;

  await Promise.all([cartStore.loadCart({ fields: 'FULL' }), cartStore.loadPaymentModes()]);

  // Check if payment mode is allowed
  if (
    cartStore.getPaymentModes.some(
      (paymentMode) => paymentMode.code === cartStore.getPaymentMode && !paymentMode.allowed,
    )
  ) {
    return navigateToWithAdobeParam('/checkout/payment/', query);
  }

  if (!cartStore.getReachedMinimumOrderValue) {
    return navigateToWithAdobeParam('/cart/', query);
  }

  if (!cartStore.getPhoneNumberIsValid) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getBillingAddress) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getDeliveryAddress) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (cartStore.getDeliveryAddressIsPickupStore) {
    try {
      await userStore.verifyAddress(cartStore.getDeliveryAddress);
    } catch (error) {
      return navigateToWithAdobeParam('/checkout/address/', { ...query, error: 'invalidPickupStation' });
    }
  }

  if (
    (!cartStore.getPaymentMode && !cartStore.getFullPaybackPayment) ||
    ([PAYMENT_MODE_BANK, PAYMENT_MODE_INVOICE].includes(cartStore.getPaymentMode) && !cartStore.getBirthdayAdult)
  ) {
    return navigateToWithAdobeParam('/checkout/payment/', query);
  }

  return null;
});
