import { PAYMENT_MODE_BANK, PAYMENT_MODE_INVOICE } from '@/utils/payment-modes';
import { PRODUCT_ALL_AVAILABLE, PRODUCT_NOT_ALL_AVAILABLE } from '@/utils/product';

function billingAddressHasDefaultCountry() {
  const { defaultCountry } = useMainStore();
  const { getBillingAddress: cartBillingAddress } = useCartStore();

  if (cartBillingAddress && defaultCountry && cartBillingAddress.country?.isocode !== defaultCountry) {
    return cartBillingAddress?.country?.isocode === defaultCountry;
  }

  return true;
}

function prepareServiceAsDeliveryOption(address) {
  const { displayName, openingSchedule, productsAvailability } = address?.storeInfo || {};
  const { line1, postalCode, town, remarks, country } = address;

  return {
    remarks,
    firstName: displayName,
    street: line1,
    postcode: postalCode,
    city: town,
    country: country?.isocode,
    storeInfo: {
      displayName,
      openingSchedule,
      productsAvailability,
    },
  };
}

async function redirectToPaymentOrSkipToSummary(query) {
  const cartStore = useCartStore();
  const userStore = useUserStore();

  // if cart is not eligible for C&C or PuDo, go to address selection
  if (
    (!cartStore.getIsClickAndCollectDeliveryAddressTypeEnabled && cartStore.getDeliveryAddress?.storeInfo) ||
    (!cartStore.getIsPickupStationDeliveryAddressTypeEnabled && cartStore.getDeliveryAddress?.pickUpStation)
  ) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  // If Click & Collect is enabled and default shipping address has remarks(storeId)
  // Check Product availability and set pickup address if valid.
  if (cartStore.getIsClickAndCollectDeliveryAddressTypeEnabled) {
    const storeId = userStore.getDefaultShippingAddress?.remarks;
    const isPickupStation = userStore.getDefaultShippingAddress?.pickUpStation;
    const isStore = userStore.getDefaultShippingAddress?.storeInfo;

    if (isStore && storeId && !isPickupStation) {
      const productsAvailability = await cartStore.findCartStoreAvailability(storeId);

      const validAvailability = [PRODUCT_ALL_AVAILABLE, PRODUCT_NOT_ALL_AVAILABLE];
      const isAvailable = validAvailability.includes(productsAvailability);

      if (isAvailable) {
        const defaultShippingAddress = userStore.getDefaultShippingAddress;
        cartStore.pickupAddress.fnStore = prepareServiceAsDeliveryOption(defaultShippingAddress);
      }
    }
  }

  // If the user does a full payback payment, go directly to summary
  if (cartStore.getFullPaybackPayment) {
    return navigateToWithAdobeParam('/checkout/summary/', query);
  }

  // Go to payment if there is no paymentMode on the cart (e.g. user does not have a default set in my account)
  if (!cartStore.getPaymentMode && !cartStore.getFullPaybackPayment) {
    return navigateToWithAdobeParam('/checkout/payment/', query);
  }

  // For paymentMode 'bank', always go to payment, as IBAN always has to be typed in
  if (cartStore.getPaymentMode === PAYMENT_MODE_BANK && !cartStore.getFullPaybackPayment) {
    return navigateToWithAdobeParam('/checkout/payment/', query);
  }

  // For paymentMode 'invoice', go to payment if user is not an adult
  if (
    cartStore.getPaymentMode === PAYMENT_MODE_INVOICE &&
    !cartStore.getBirthdayAdult &&
    !cartStore.getFullPaybackPayment
  ) {
    return navigateToWithAdobeParam('/checkout/payment/', query);
  }

  return navigateToWithAdobeParam('/checkout/summary/', query);
}

export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const userStore = useUserStore();
  const cartStore = useCartStore();
  const { query } = to;

  // Bypass checkout for store editors
  if (userStore.user?.isStoreEditor) {
    return navigateToWithAdobeParam(useNuxtApp().$i18n.t('url.storeEdit.full'), query);
  }

  // Load latest cart data and handle other redirect conditions
  await cartStore.loadCart({ fields: 'FULL' });

  if (!cartStore.getReachedMinimumOrderValue) {
    return navigateToWithAdobeParam('/cart/', query);
  }

  if (!cartStore.getPhoneNumberIsValid) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!billingAddressHasDefaultCountry()) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getBillingAddress || !cartStore.getDeliveryAddress) {
    if (userStore.getIsAuthenticated) {
      if (await cartStore.autoFillCart()) {
        return redirectToPaymentOrSkipToSummary(query);
      }

      return navigateToWithAdobeParam('/checkout/address/', query);
    }

    if (cartStore.getIsGuest) {
      return navigateToWithAdobeParam('/checkout/address/', query);
    }

    return navigateToWithAdobeParam('/checkout/start/', query);
  }

  if (cartStore.getDeliveryAddressIsPickupStore) {
    try {
      await userStore.verifyAddress(cartStore.getDeliveryAddress);
    } catch (error) {
      return navigateToWithAdobeParam('/checkout/address/', { ...query, error: 'invalidPickupStation' });
    }
  }

  return redirectToPaymentOrSkipToSummary(query);
});
