export const util = import.meta.server ? await import('node:util') : undefined;

const logLevels = {
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
  log: 4,
};

const getLogLevel = () =>
  (import.meta.client ? import.meta.env.NUXT_PUBLIC_LOG_LEVEL : process.env.NUXT_PUBLIC_LOG_LEVEL) || 'error';

const isEnabled = (method) => logLevels[method] <= logLevels[getLogLevel()];

/**
 * Creates a bound logging function which includes a logging prefix,
 * or a noop function when logging is disabled.
 *
 * @param {string} method
 * @returns {function(...any)}
 */
const createLogMethod = (method) => {
  if (isEnabled(method)) {
    // eslint-disable-next-line no-console
    return Function.prototype.bind.call(console[method], console, `[storefront]`);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prettier/prettier
  return (...args) => { };
};

export const logger = {
  isEnabled,
  getLogLevel,
  log: (() => createLogMethod('log'))(),
  debug: (() => createLogMethod('log'))(),
  info: (() => createLogMethod('info'))(),
  warn: (() => createLogMethod('warn'))(),
  error: (() => createLogMethod('error'))(),
  inspect: (value) => {
    if (import.meta.client) {
      return JSON.stringify(value, null, 2);
    } else {
      // Nitro API routes are super funky when even thinking about importing ANYTHING, so fallback for good measure
      return util?.inspect(value, { colors: true }) ?? JSON.stringify(value, null, 2);
    }
  },
};

/**
 * IIFEs are used to immediately invoke the log methods (log, debug, info, etc.)
 * and return bound functions to the call site. This ensures that the correct
 * point of invocation is shown in the console, rather than logging a line
 * within this logger utility itself.
 */
export default logger;
