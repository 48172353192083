import { type BaseStoreKeys } from '@/utils/baseStoreConfig';

export const getBaseStoresIsoMapping = (config = useRuntimeConfig()) => ({
  bp: config.public.baseStoreBp,
  de: config.public.baseStoreDe,
  at: config.public.baseStoreAt,
  lu: config.public.baseStoreLu,
  pl: config.public.baseStorePl,
  fr: config.public.baseStoreFr,
  dk: config.public.baseStoreDk,
  fr_BE: config.public.baseStoreBe,
  nl_BE: config.public.baseStoreBe,
  de_CH: config.public.baseStoreCh,
  fr_CH: config.public.baseStoreCh,
  ie: config.public.baseStoreIe,
});

export const languagesByBaseStoreMapping = (config = useRuntimeConfig()) => ({
  fressnapfAT: 'de',
  fressnapfBP: 'de',
  fressnapfCH: config.public.defaultLangCh as string,
  fressnapfDE: 'de',
  fressnapfLU: 'de',
  maxizooBE: config.public.defaultLangBe as string,
  maxizooFR: 'fr',
  maxizooIE: 'ie',
  maxizooPL: 'pl',
  maxizooDK: 'dk',
});

export const baseStoresUrlMapping = (config = useRuntimeConfig()): { [p: string]: string } => ({
  [config.public.domainBp]: config.public.baseStoreBp,
  [config.public.domainDe]: config.public.baseStoreDe,
  [config.public.domainAt]: config.public.baseStoreAt,
  [config.public.domainLu]: config.public.baseStoreLu,
  [config.public.domainPl]: config.public.baseStorePl,
  [config.public.domainFr]: config.public.baseStoreFr,
  [config.public.domainDk]: config.public.baseStoreDk,
  [config.public.domainBe]: config.public.baseStoreBe,
  [config.public.domainCh]: config.public.baseStoreCh,
  [config.public.domainIe]: config.public.baseStoreIe,
});

export const getDomainsByBaseStore = (config = useRuntimeConfig()) => ({
  [config.public.baseStoreAt]: config.public.domainAt,
  [config.public.baseStoreBp]: config.public.domainbp,
  [config.public.baseStoreCh]: config.public.domainCh,
  [config.public.baseStoreDe]: config.public.domainDe,
  [config.public.baseStoreLu]: config.public.domainLu,
  [config.public.baseStoreBe]: config.public.domainBe,
  [config.public.baseStoreDk]: config.public.domainDk,
  [config.public.baseStoreFr]: config.public.domainFr,
  [config.public.baseStoreIe]: config.public.domainIe,
  [config.public.baseStoreIt]: config.public.domainIt,
  [config.public.baseStorePl]: config.public.domainPl,
});

export const getDefaultLocalesByBaseStore = (config = useRuntimeConfig()) => ({
  default: 'de',
  maxizooBE: config.public.defaultLangBe,
  fressnapfCH: config.public.defaultLangCh,
});

export const getIsocodesByLocale = (config = useRuntimeConfig()) => ({
  nl_BE: config.public.isoNl,
  fr_BE: config.public.isoFr,
  de_CH: config.public.isoDe,
  fr_CH: config.public.isoFr,
});

export const possibleLanguagesMapping = (config = useRuntimeConfig()) => ({
  fressnapfCH: [config.public.isoDe, config.public.isoFr],
  maxizooBE: [config.public.isoNl, config.public.isoFr],
});

export const getBaseStoreAndLanguage = (host: string, url: string, i18nCookie: string, config = useRuntimeConfig()) => {
  const hostWithoutPort = host.split(':')[0];

  const baseStoresByDomain = baseStoresUrlMapping(config);
  const languagesByBaseStore = languagesByBaseStoreMapping(config);
  const possibleLanguages = possibleLanguagesMapping(config);

  const baseStore = baseStoresByDomain[hostWithoutPort] as BaseStoreKeys;

  if (!baseStore) {
    throw createError({
      statusCode: 500,
      statusMessage: `No baseStore found for domain '${hostWithoutPort}'.`,
      fatal: true,
    });
  }

  const isMultilang = baseStore in possibleLanguages;

  let language: string;

  // Value from cookie i18n_redirected overrules the others
  if (i18nCookie) {
    language = i18nCookie;
  } else {
    language = languagesByBaseStore[<keyof typeof languagesByBaseStore>baseStore];

    if (!language) {
      throw createError({
        statusCode: 500,
        statusMessage: `No language found for baseStore '${baseStore}'`,
        fatal: true,
      });
    }

    // If url starts with language prefix, use that instead of the default language
    const startOfUrl = url.split('/')[1];

    if (isMultilang && possibleLanguages[<keyof typeof possibleLanguages>baseStore].includes(startOfUrl)) {
      language = `${startOfUrl}_${baseStore.slice(-2)}`;
    }
  }

  const baseStoreConfig = getBaseStoreConfig(baseStore);

  return {
    baseStore,
    baseStoreConfig,
    language,
    isMultilang,
  };
};
