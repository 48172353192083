export const PAYMENT_MODE_PAYPAL = 'payPal';
export const PAYMENT_MODE_INVOICE = 'invoice';
export const PAYMENT_MODE_BANK = 'bank';
export const PAYMENT_MODE_CREDITCARD = 'creditcard';
export const PAYMENT_MODE_ADVANCE = 'advance';
export const PAYMENT_MODE_CASHONDELIVERY = 'cashOnDelivery';
export const PAYMENT_MODE_P24 = 'p24';
export const PAYMENT_MODE_ADYEN_PREFIX = 'adyen_';
export const PAYMENT_MODE_ADYEN_BANCONTACT = 'adyen_bancontact';
export const PAYMENT_MODE_ADYEN_ONLINEBANKING = 'adyen_p24';
export const PAYMENT_MODE_ADYEN_PAYCONIQ = 'adyen_payconiq';
export const PAYMENT_MODE_ADYEN_CREDITCARD = 'adyen_creditcard';
export const PAYMENT_MODE_ADYEN_TWINT = 'adyen_twint';
export const PAYMENT_MODE_ADYEN_MOBILEPAY = 'adyen_mobilepay';
export const PAYMENT_MODE_ADYEN_BLIK = 'adyen_blik';

export const ADYEN_COMPONENT_CARD = 'card';
export const ADYEN_COMPONENT_BANCONTACT = 'bcmc';
export const ADYEN_COMPONENT_BLIK = 'blik';
export const ADYEN_COMPONENT_ONLINEBANKING_PL = 'onlineBanking_PL';
export const ADYEN_ACTION_QRCODE = 'qrCode';
export const ADYEN_ACTION_3DS = 'threeDS2';
