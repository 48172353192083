import { OCC_USER_ID_ANONYMOUS } from '@/utils/occ-constants';
import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class AnalyticsApi extends AbstractHybrisApi {
  get(userId, cartId, orderId, filterOptions) {
    if (userId === OCC_USER_ID_ANONYMOUS && !cartId && !orderId) {
      return Promise.resolve({ data: {} });
    }

    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/analytics`,
      params: {
        cartId,
        orderId,
        filterOptions: filterOptions?.join(',') || null,
      },
    });
  }
}
